import debounce from 'lodash.debounce';

import Dialogs from '../dialogs/a11y-dialog-component';
import updateMobileCartCounter from '../cart/counter';
import handleError from '../cart/handleError';
import setCartVisibility from '../cart/setCartVisibility';
import retrieveFormData from '../cart/retrieveFormData';
import ajaxCrossSellPopinProduct from '../crossSellPopin/ajax';
import { ADD_TO_CART, IS_ADDING_TO_CART, on } from '../../events';
import analytics from '../../analytics';

function addProduct(button) {
  const form = button.form || document.querySelector('*[data-component="product"] form[data-cart-add-product]');

  if (!form.reportValidity()) {
    return;
  }

  const action = new URL(form.action);

  if (button.dataset.lastMinute) {
    action.searchParams.set('last_minute', 1);
  }

  button.disabled = true;

  document.dispatchEvent(
    new CustomEvent(IS_ADDING_TO_CART, {
      detail: {
        loading: true,
        id: button.id,
      },
    }),
  );

  const formData = retrieveFormData(form, button.dataset.customisation === '0');

  fetch(action.toString(), {
    method: 'POST',
    body: formData,
  })
    .then((response) => response.text())
    .then((body) => {
      const lists = document.querySelectorAll('li.js-dynamic-menu');
      lists.forEach((list) => {
        list.outerHTML = body;
      });

      if (form.dataset.modalId && !form.hasAttribute('data-keep-modal-open')) {
        Dialogs.hide(form.dataset.modalId);
      }

      const carts = document.querySelectorAll('.js-cart');
      let hasError = false;

      carts.forEach((cart, index) => {
        if ('error' in cart.dataset && index === 0) {
          handleError(cart);
          hasError = true;
          return;
        }

        setCartVisibility(cart);
      });
      updateMobileCartCounter();

      if (button.dataset.popinCrossSellPath && !hasError) {
        const popinCrossSellProductEnabled = button.getAttribute('data-popin-cross-sell-product-enabled') ?? false;
        ajaxCrossSellPopinProduct(button.dataset.popinCrossSellPath, popinCrossSellProductEnabled);
      }

      document.dispatchEvent(
        new CustomEvent(ADD_TO_CART, {
          detail: {
            button,
            ean: formData.get('ean[]'),
          },
        }),
      );

      if (button.dataset.tracking) {
        const tracking = JSON.parse(button.dataset.tracking);

        if (button.dataset.customisation === '0') {
          analytics.productWithoutCustomisation(tracking);
        }

        if (button.dataset.customisation === '1') {
          analytics.productWithCustomisation(tracking);
        }

        analytics.productAddedToCart(tracking);
      }
    })
    .finally(() => {
      button.disabled = false;

      document.dispatchEvent(
        new CustomEvent(IS_ADDING_TO_CART, {
          detail: {
            loading: false,
            id: button.id,
          },
        }),
      );

      // This is a responsibility nightmare but I don't know how to handle it better
      if (button.hasAttribute('data-customisation')) {
        Dialogs.hide('ajax-modal');
        Dialogs.hide('ajax-modal2');
      }
    });
}

const DEBOUNCE_WAIT = 1000;
const DEBOUNCE_OPTIONS = { leading: true, trailing: false, maxWait: DEBOUNCE_WAIT }; // cf. https://lodash.com/docs/4.17.15#debounce

export default function ajaxAddProduct() {
  on('button[data-cart-add-product]', 'click', debounce(addProduct, DEBOUNCE_WAIT, DEBOUNCE_OPTIONS));

  on(
    'form[data-cart-add-product]',
    'submit',
    debounce(
      (form, event) => {
        event.preventDefault();

        // Check if there is the ajax button available
        if (form.querySelector('button[data-cart-add-product]')) {
          addProduct(form.querySelector('button[data-cart-add-product]'));
        }
      },
      DEBOUNCE_WAIT,
      DEBOUNCE_OPTIONS,
    ),
  );
}
