import React, { useRef } from 'react';
import { createPortal } from 'react-dom';
import translate from '../../../../translate';
import alarmImg from '../../../../../images/rse/alarm.png';
var LastCall = function () {
    var container = document.querySelector('[data-product-last-call]');
    var target = useRef(container).current;
    var hasMounted = useRef(false);
    if (!target)
        return null;
    // Clear container content to avoid multiple last call mount
    if (!hasMounted.current) {
        target.innerHTML = '';
        hasMounted.current = true;
    }
    return (React.createElement(React.Fragment, null, container
        ? createPortal(React.createElement("div", { className: "u-flex u-flex-cross-start u-mt-xs-sm" },
            React.createElement("img", { src: alarmImg, alt: "", loading: "lazy", className: "u-flex-item-none u-mt-xxs-bis" }),
            React.createElement("p", { className: "u-mt-n u-ml-xs-sm u-text-md-plus u-text-md-plus@sm u-color-gray-600" },
                React.createElement("em", null, translate('front.product.index.last_call_message')))), container)
        : null));
};
export default LastCall;
