/* select dropdown
 ========================================================================== */

function findMatchAndFocus(event, dropdown) {
  const match = Array.from(dropdown.querySelectorAll('label')).find((label) => {
    return label.textContent.trim().toLowerCase().indexOf(event.key.toLowerCase()) === 0;
  });

  if (match) {
    match.parentElement.focus();
  }
}

function findAndFilter(inputValue, dropdownList) {
  const items = Array.from(dropdownList.getElementsByTagName('li'));

  items.forEach((item) => {
    if (item.textContent.trim().toLowerCase().indexOf(inputValue.toLowerCase()) === 0) {
      item.classList.remove('u-hidden');
    } else {
      item.classList.add('u-hidden');
    }
  });
}

function addSelectDropdown(selectDropdown) {
  const trigger = document.querySelector(selectDropdown.dataset.targetTrigger);
  const dropdown = document.querySelector(selectDropdown.dataset.targetDropdown);

  const selectElement = (target) => {
    if (target.textContent.length > 0 && target.nodeName !== 'UL') {
      const isAvailableElement = target.closest('[data-item]').querySelector('[data-item-available]');
      const isAvailable = isAvailableElement ? isAvailableElement.dataset?.itemAvailable === '1' : true;
      const textContent = target.textContent.trim();

      if (!isAvailable) {
        const wrapper = document.createElement('s');
        const textNode = document.createTextNode(textContent);

        wrapper.appendChild(textNode);
        trigger.innerHTML = '';
        trigger.appendChild(wrapper);
      } else {
        const input = target.querySelector('input') || target.control;

        if (input) {
          input.checked = true;
        }

        trigger.textContent = textContent;
      }
    }

    dropdown.setAttribute('aria-hidden', true);
  };

  const onClick = (event) => {
    selectElement(event.target);
  };

  dropdown.addEventListener('click', onClick);
  document.addEventListener('keyup', (event) => {
    if (dropdown.ariaHidden === 'false') {
      findMatchAndFocus(event, dropdown);

      if (event.key === 'Enter') {
        selectElement(document.activeElement);
      }
    }
  });

  const inputFilter = dropdown.querySelector('[data-select-filter="input"]');
  const dropdownList = dropdown.querySelector('[data-select-filter="list"]');
  if (inputFilter) {
    inputFilter.addEventListener('click', (event) => {
      event.stopImmediatePropagation();
    });

    inputFilter.addEventListener('keyup', () => {
      findAndFilter(inputFilter.value, dropdownList);
    });
  }

  // Init value if checked
  const checked = dropdown.querySelector('[type="radio"]:checked');
  if (checked) {
    const textContent = checked.nextElementSibling.textContent.trim();
    trigger.textContent = textContent;
  }
}

export default function addSelectDropdowns(parent) {
  const selectDropdowns = (parent || document).querySelectorAll('[data-component="select"]');

  selectDropdowns.forEach((selectDropdown) => {
    addSelectDropdown(selectDropdown);
  });
}
