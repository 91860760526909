/* crossSellPopin/ajax
 ========================================================================== */

import Dialogs from '../dialogs/a11y-dialog-component';
import addSelectDropdowns from '../dropdowns/select';
import addDropdown from '../dropdowns/lib';
import routes from '../../routes';
import { renderProductCta } from '../product/cta/ProductCta';
import { ADD_TO_CART, PRODUCT_STATE_CHANGE } from '../../events';
import { crossSell } from '../sliders/sliders';
import initCrossSellProductPopin from './init';

const DIALOG_AJAX_MODAL = 'ajax-modal';

export default function ajax(path, crossSellProductEnabled) {
  const url = `${routes.cross_sell_popin}?path=${path}`;
  const dialog = document.querySelector('#ajax-modal');
  Dialogs.show(DIALOG_AJAX_MODAL);
  const modalContentContainer = dialog.querySelector('[data-modal-content]');

  fetch(url, {
    method: 'GET',
  })
    .then((response) => response.text())
    .then((content) => {
      if (crossSellProductEnabled) {
        dialog.className =
          'c-dialog c-dialog--xl c-dialog--border-none c-dialog--wide@sm c-dialog--box-xl c-dialog--box-md@md c-dialog--box-sm-bis@sm c-dialog--box-n-top';
      } else {
        dialog.className =
          'c-dialog c-dialog--lg c-dialog--border-none c-dialog--wide@sm c-dialog--box-xxl c-dialog--box-xl@sm c-dialog--box-n-top';
      }

      modalContentContainer.innerHTML = content;

      if (crossSellProductEnabled) {
        initCrossSellProductPopin();
      } else {
        crossSell();
      }

      const dropdowns = modalContentContainer.querySelectorAll('.js-dropdown-select');
      dropdowns.forEach((dropdown) => addDropdown(dropdown.id));
      addSelectDropdowns(document.getElementById('cross-sell-modal'));

      // Update CTA on ean change
      modalContentContainer.querySelectorAll('input[name="ean[]"]').forEach((input) => {
        input.addEventListener('change', (e) => {
          document.dispatchEvent(
            new CustomEvent(PRODUCT_STATE_CHANGE, {
              detail: {
                productHash: e.target.dataset.productHash,
                currentVariantHash: e.target.dataset.variantHash,
                currentItemHash: e.target.dataset.itemHash,
              },
            }),
          );
        });
      });

      renderProductCta({ container: dialog, inDialogContext: true });

      // Hide modal when all cross sell products have been added to cart
      const nbCrossSellProducts = modalContentContainer.querySelectorAll('.c-slider__item').length;
      let nbAddedProducts = 0;

      document.addEventListener(ADD_TO_CART, () => {
        nbAddedProducts += 1;

        if (nbAddedProducts >= nbCrossSellProducts) {
          Dialogs.hide(DIALOG_AJAX_MODAL);
        }
      });
    });
}
