import { crossSellProductPopin as sliderCrossSellProductPopin } from '../sliders/sliders';
export default function initCrossSellProductPopin() {
    sliderCrossSellProductPopin();
    var modal = document.querySelector('#cross-sell-product-modal');
    modal.querySelectorAll('.c-dropdown__item').forEach(function (el) {
        el.addEventListener('click', function () {
            var input = el.querySelector('input');
            var variantImage = input.getAttribute('data-variant-image');
            var form = el.closest('form');
            var image = form.querySelector('img');
            image.src = variantImage;
        });
    });
}
